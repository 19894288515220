import {GET_FORM_SCHEMA_URL, PREVIEW_FORM_SCHEMA_URL} from 'constants/backend';
import BackendResponse from 'types/BackendResponse';
import IFormSchema from 'types/IFormSchemaJSON';
import React from "react";

function getFormUrl(formName: string, isPreview: boolean) {
    const aliasSearchParam =
        new URLSearchParams({
            alias: formName,
        }).toString();
    
    const baseUrl = isPreview ? PREVIEW_FORM_SCHEMA_URL : GET_FORM_SCHEMA_URL;

    return `${baseUrl}${aliasSearchParam}`
}

const fetchForm = (
    headers: Headers,
    setFormSchemaJSON: React.Dispatch<React.SetStateAction<IFormSchema>>,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    formName: string,
    isPreview: boolean
) => {
    const url = getFormUrl(formName, isPreview);

    fetch(url, {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
    })
        .then((response) => response.json())
        .then((result: BackendResponse) => {
            if (!result.schema) throw new Error('No schema found');
            setError(false);

            return setFormSchemaJSON(result.schema);
        })
        .catch(() => setError(true));
};

export default fetchForm;
