import headers from 'config/headers';
import { CHECK_VALID_FORM_URL } from 'constants/backend';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BackendResponse from 'types/BackendResponse';

const Valid = () => {
    const [searchParams] = useSearchParams();

    const [formName, setFormName] = useState<string>();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [validatorErrors, setValidatorErrors] = useState<string[]>([]);

    const ValidatorErrors = () => {
        return validatorErrors.length > 0 ? (
            <div className="p-7 border border-gray rounded">
                <h1 className="font-medium pb-5">
                    Skjemaet har følgende feil:
                </h1>
                <div className=" divide-y divide-slate-200 leading-loose">
                    {validatorErrors.map((error) => {
                        return <p key={error}>{error}</p>;
                    })}
                </div>
            </div>
        ) : (
            <></>
        );
    };

    useEffect(() => {
        const formNameSearchParam = new URLSearchParams({
            formName: searchParams.get('skjema'),
        }).toString();

        const url = `${CHECK_VALID_FORM_URL}${formNameSearchParam}`;

        fetch(`${url}`, {
            method: 'POST',
            headers: headers,
            redirect: 'follow',
        })
            .then((response) => response.json())
            .then((result: BackendResponse) => {
                for (const [_, validationError] of Object.entries(
                    result.fieldErrors
                )) {
                    setValidatorErrors((oldValidatorErrors) => [
                        ...oldValidatorErrors,
                        validationError,
                    ]);
                }

                if (result.isValid) {
                    return setIsValid(true);
                }

                return setIsValid(false);
            })
            .catch((error) => console.log('error', error));
    }, [searchParams]);

    if (isValid)
        return (
            <div className="grid justify-self-center justify-items-center m-5 ">
                <p className="border-2 p-1 mt-16 border-green w-32 text-xl text-center align-middle bg-green rounded text-white">
                    Gyldig
                </p>
            </div>
        );

    if (!validatorErrors.length && !isValid) return <></>;

    if (validatorErrors.length && !isValid)
        return (
            <div className="grid grid-rows-1 justify-self-center justify-items-center m-5 ">
                <p className="border-2 mb-2 p-2 mt-16 border-red w-32 text-xl text-center bg-red rounded text-white">
                    Ikke gyldig
                </p>
                <div className="">
                    <ValidatorErrors />
                </div>
            </div>
        );
};

export default Valid;
