import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const QR = () => {
    const [url, setUrl] = useState<string>();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // @ts-ignore
        fetchUrl = QRCode.toDataURL(
            `${window.location.origin}/?skjema=${searchParams.get('skjema')}`
        )
            .then(setUrl)
            .catch((err) => {
                console.error(err);
            });
    }, []);

    return (
        <div className="flex justify-center items-center h-screen">
            {url && (
                <img
                    src={url}
                    className="sm:h-32 md:h-48 lg:h-64 xl:h-80"
                    alt={'Skjema QR kode'}
                />
            )}
        </div>
    );
};

export default QR;
