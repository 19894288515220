import { SUBMIT_FORM_URL } from 'constants/backend';
import React from 'react';

const submitForm = (
    data: BodyInit,
    headers: Headers,
    formName: string,
    code: string,
    setRegNrError: React.Dispatch<React.SetStateAction<boolean>>,
    setCompleted: React.Dispatch<React.SetStateAction<boolean>>,
    setOTPValid: React.Dispatch<React.SetStateAction<boolean>>,
    setSubmitError: React.Dispatch<React.SetStateAction<boolean>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const formNameSearchParam = new URLSearchParams({
        formName: formName,
    }).toString();

    const codeSearchParam = new URLSearchParams({
        code: code,
    }).toString();

    const url = `${SUBMIT_FORM_URL}${formNameSearchParam}&${codeSearchParam}`;

    fetch(`${url}`, {
        method: 'POST',
        headers: headers,
        body: data,
        redirect: 'follow',
    })
        .then((response) => {
            if (response.ok) {
                setCompleted(true);
            } else {
                setSubmitError(true);
            }
            setIsLoading(false);

            return response.json();
        })
        .catch((error: object) => {
            if (error?.toString()?.includes('code')) {
                setOTPValid(false);
            } else if (error?.toString()?.includes('carModel')) {
                setRegNrError(true);
            } else {
                setSubmitError(true);
            }
            setIsLoading(false);
        });
};

export default submitForm;
