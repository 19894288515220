import { PrimaryButton } from '@moller/design-system';

interface SubmitProps {
    label: string;
    isLoading: boolean;
}

const Submit = ({ label, isLoading }: SubmitProps) => {
    return (
        <PrimaryButton type="submit" loading={{ isLoading, loadingText: label }}>
            {label}
        </PrimaryButton>
    );
};

export default Submit;
