import {
    validAddress,
    validCellPhoneNumber,
    validEmail,
    validName,
    validRegistrationNumber,
    validZipCode,
} from '@moller/design-system/utilities/validation';

const AvailableValidators = {
    firstName: validName,
    lastName: validName,
    address: validAddress,
    cellPhoneNumber: validCellPhoneNumber,
    email: validEmail,
    regNr: validRegistrationNumber,
    zipCode: validZipCode,
};

export default AvailableValidators;
