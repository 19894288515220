import determineEnvironment, { Environment } from 'utils/determineEnvironment';

const getBaseUrl = () => {
  const env = determineEnvironment();
  if (env === Environment.Local) {
    return 'http://localhost:5273/';
  }
  if (env === Environment.Dev) {
    return 'https://forms-api.dev.mollercloud.no/';
  }
  return 'https://forms-api.prod.mollercloud.no/';
};

export const BASE_URL = getBaseUrl();

export const GET_FORM_SCHEMA_URL = `${BASE_URL}get?`;

export const PREVIEW_FORM_SCHEMA_URL = `${BASE_URL}preview?`;

export const SUBMIT_FORM_URL = `${BASE_URL}submit?`;

export const CHECK_VALID_FORM_URL = `${BASE_URL}valid?`;

export const GET_DEALERSHIPS_URL = `${BASE_URL}dealerships{0}`;

export const GET_VERIFY_URL = `${BASE_URL}verify`;

export const GET_CARMODELS_URL = `${BASE_URL}carmodels{0}`;
