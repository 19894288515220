type IFrameEvent = {
    type: 'FORM_LOADED' | 'FORM_SUBMITTED' | 'FORM_ERROR';
};

function isInsideIFrame() {
    return window.self !== window.top;
}

export function sendIFrameEvent(
    event: IFrameEvent,
    targetOrigin: string | null
) {
    if (!isInsideIFrame()) {
        return;
    }
    if (!targetOrigin) {
        console.log('No target origin set for iframe event, skipping...');
        return;
    }
    window.top.postMessage(event, targetOrigin);
}
