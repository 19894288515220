import {datadogLogs} from '@datadog/browser-logs'
import {datadogRum} from '@datadog/browser-rum'
import determineEnvironment, {Environment} from './determineEnvironment'
import {BASE_URL} from 'constants/backend'

export const initDatadog = () => {
    const applicationId = '7b674ae2-763a-429c-8a20-7c50d7f87e32';
    const clientToken = 'pub1f2c5d0edbba596c82e1d58cc48dad42';
    const site = 'datadoghq.eu';
    const service = 'forms-frontend';
    
    const serverURL = new URL(BASE_URL).origin;
    const env = determineEnvironment();
    const version = import.meta.env.VITE_APP_VERSION ?? '';
    
    if (env === Environment.Local) {
        return;
    }

    datadogLogs.init({
        clientToken,
        site,
        service,
        env,
        version,
        forwardErrorsToLogs: true,
        sampleRate: 100,
    });


    datadogRum.init({
        applicationId,
        clientToken,
        site,
        service,
        env,
        version,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingOrigins: [window.location.origin, serverURL],
    });
    datadogRum.startSessionReplayRecording();
};
