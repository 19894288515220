import { Headline } from '@moller/design-system';

const ErrorMessage = () => {
    return (
        <div className="grid place-items-center h-screen w-full">
            <div className="grid w-96 text-center">
                <Headline className="text-2xl ">Skjemaet finnes ikke.</Headline>
            </div>
        </div>
    );
};

export default ErrorMessage;
