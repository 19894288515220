import { Headline } from '@moller/design-system';

const feedback = () => {
    return (
        <div className="grid place-items-center h-screen w-full">
            <div className="grid w-96 text-center">
                <Headline className="text-2xl ">
                    Takk for din henvendelse!
                </Headline>
                <p className="text-l">Vi behandler den så snart vi kan.</p>
            </div>
        </div>
    );
};
export default feedback;
