import {
    formInputs,
    FormProvider,
    useLocalFormState,
    Headline,
    Subtitle,
} from '@moller/design-system';
import parse from 'html-react-parser';
import { ValidatorMap } from '@moller/design-system/utilities/validation';
import headers from 'config/headers';
import React, { useRef, useState } from 'react';
import { FormState } from 'types/FormState';
import IFormInput from 'types/IFormInput';
import IFormSchema from 'types/IFormSchemaJSON';
import submitForm from 'utils/submitForm';
import Submit from './buttons/Submit';
import Date from './fields/Date';
import Select from './fields/Select';
import Time from './fields/Time';
import SMSVerification from './SMSVerification';
import styled, { css } from 'styled-components';

const { TextField, TextArea } = formInputs<FormState>();

interface IFormContent {
    initialState: FormState;
    validator: ValidatorMap<IFormInput>;
    formName: string;
    formSchemaJSON: IFormSchema;
    setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
    isPreview?: boolean;
}

const HTMLElement = styled.span`
    ${({ theme }) => css`
        a {
            color: ${theme.ColorPrimary};
            text-decoration: underline;
        }
    `}
`;

const SubmitWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        margin: ${theme.SpacingS}px 0 0;

        button {
            display: flex;
            align-self: flex-start;
        }
    `}
`;

const SubmitError = styled.span`
    ${({ theme }) => css`
        display: flex;
        margin: 0 0 ${theme.SpacingXs}px;
        color: ${theme.ColorError};

        a {
            font-weight: 500;
            text-decoration: underline;
        }
    `}
`;

const FormContent: React.FC<IFormContent> = ({
    initialState,
    validator,
    formName,
    formSchemaJSON,
    setCompleted,
    isPreview,
}) => {
    const form = useLocalFormState(initialState, validator);
    const [regNrError, setRegNrError] = useState(false);
    const [OTPFieldEnabled, setOTPFieldEnabled] = useState(false);
    const [OTP, setOTP] = useState('0000');
    const [OTPValid, setOTPValid] = useState(true);
    const [submitError, setSubmitError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const dateFieldRef = useRef(null);
    const timeFieldRef = useRef(null);

    // Submit data to form.io after form data entry
    const handleSubmit = (formAnswers: FormState) => {
        setSubmitError(false);
        setIsLoading(true);
        const submitPayload = {
            data: formAnswers,
            version: formSchemaJSON.version
        };
        if (isPreview) {
            setCompleted(true);
            console.log('Pretending to submit preview');
            return;
        }
        
        submitForm(
            JSON.stringify(submitPayload),
            headers,
            formName,
            OTP,
            setRegNrError,
            setCompleted,
            setOTPValid,
            setSubmitError,
            setIsLoading
        );
    };

    return (
        <div className="grid place-items-center h-screen w-full">
            <FormProvider
                id="exampleForm"
                form={form}
                onSubmit={(data) => handleSubmit(data)}
                hideNecessityText
                name={formSchemaJSON.name}
            >
                <div
                    className="grid"
                    style={{ rowGap: '12px', padding: '16px' }}
                >
                    {formSchemaJSON &&
                        formSchemaJSON.components.map((component) => {
                            if (
                                component.type === 'htmlelement' &&
                                component.properties?.title === 'true'
                            ) {
                                return (
                                    <Headline key={component.key}>
                                        {parse(component?.content)}
                                    </Headline>
                                );
                            }
                            if (
                                component.type === 'htmlelement' &&
                                component.properties?.subtitle === 'true'
                            ) {
                                return (
                                    <Subtitle key={component.key}>
                                        {parse(component?.content)}
                                    </Subtitle>
                                );
                            }
                            return null;
                        })}
                    {form.necessityText}
                    {formSchemaJSON &&
                        formSchemaJSON.components.map((component) => {
                            if (component.type === 'textfield') {
                                if (
                                    component.properties?.validator === 'regNr'
                                ) {
                                    return (
                                        <TextField
                                            key={component.key}
                                            field={component.key}
                                            label={component.label}
                                            validity={
                                                regNrError
                                                    ? {
                                                          type: 'error',
                                                          message:
                                                              'Modellen eksisterer ikke',
                                                      }
                                                    : {
                                                          type: 'none',
                                                      }
                                            }
                                        />
                                    );
                                }
                                if (component.key === 'cellPhoneNumber') {
                                    return (
                                        <>
                                            <TextField
                                                field={component.key}
                                                label={component.label}
                                                key={component.key}
                                                prefix="+47"
                                            />
                                            {component.validate?.required &&
                                                component.properties
                                                    ?.smsvalidation ===
                                                    'true' && (
                                                    <SMSVerification
                                                        phoneNumber={
                                                            form?.state
                                                                ?.validated
                                                                ?.cellPhoneNumber
                                                        }
                                                        setOTPFieldEnabled={
                                                            setOTPFieldEnabled
                                                        }
                                                        setOTP={setOTP}
                                                        OTPValid={OTPValid}
                                                        setOTPValid={
                                                            setOTPValid
                                                        }
                                                        OTPFieldEnabled={
                                                            OTPFieldEnabled
                                                        }
                                                    />
                                                )}
                                        </>
                                    );
                                }

                                return (
                                    <TextField
                                        field={component.key}
                                        label={component.label}
                                        key={component.key}
                                    />
                                );
                            } else if (component.type === 'button') {
                                return (
                                    <SubmitWrapper key={component.key}>
                                        {submitError && (
                                            <SubmitError>
                                                {parse(
                                                    '<p>Beklager, noe gikk galt. Vennligst prøv igjen senere eller kontakt kundeservice på e-post <a href="mailto:kundeservice@moller.no">kundeservice@moller.no</a></p>'
                                                )}
                                            </SubmitError>
                                        )}
                                        <Submit
                                            label={component.label}
                                            isLoading={isLoading}
                                        />
                                    </SubmitWrapper>
                                );
                            } else if (component.type === 'textarea') {
                                return (
                                    <TextArea
                                        field={component.key}
                                        label={component.label}
                                        key={component.key}
                                    />
                                );
                            } else if (component.type === 'datetime') {
                                return (
                                    <Date
                                        componentKey={component.key}
                                        label={component.label}
                                        key={component.key}
                                        inputRef={dateFieldRef}
                                        required={component.validate?.required}
                                    />
                                );
                            } else if (component.type === 'time') {
                                return (
                                    <Time
                                        componentKey={component.key}
                                        label={component.label}
                                        key={component.key}
                                        inputRef={timeFieldRef}
                                        required={component.validate?.required}
                                    />
                                );
                            } else if (component.type === 'select') {
                                return (
                                    <Select
                                        component={component}
                                        key={component.key}
                                        form={form}
                                    />
                                );
                            } else if (
                                component.type === 'htmlelement' &&
                                component.properties?.subtitle !== 'true' &&
                                component.properties?.title !== 'true'
                            ) {
                                return (
                                    <HTMLElement key={component.key}>
                                        {parse(component?.content)}
                                    </HTMLElement>
                                );
                            }

                            return null;
                        })}
                </div>
            </FormProvider>
        </div>
    );
};

export default FormContent;
