import { GET_DEALERSHIPS_URL } from 'constants/backend';
import BackendResponse from 'types/BackendResponse';
import format from 'string-format';

export const fetchDealers = (headers: Headers, dealerGroup: string, brand: string, dealerType: string) => {
    const dealerGroupParam = dealerGroup ? `?dealergroup=${dealerGroup}` : '';
    const brandParam = brand ? `&brand=${brand}` : '';
    const dealerTypeParam = dealerType ? `&dealertype=${dealerType}` : '';

    const url = format(GET_DEALERSHIPS_URL, dealerGroupParam + brandParam + dealerTypeParam);
    
    return fetch(url, {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
    })
        .then((response) => response.json())
        .then((result: BackendResponse) => result.response)
        .catch((error) => console.log('error', error));
};
