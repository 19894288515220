export enum Environment {
    Prod = 'prod',
    Dev = 'dev',
    Local = 'local',
}

const determineEnvironment = (): Environment => {
    if (process.env.NODE_ENV !== 'production') {
        return Environment.Local;
    }
    if (
        window.location.origin === 'https://forms.dev.mollercloud.no' ||
        window.location.origin === 'https://forms-frontend.dev.mollercloud.no'
    ) {
        return Environment.Dev;
    }
    return Environment.Prod;
};

export default determineEnvironment;
