import { Label, Icon } from '@moller/design-system';
import headers from 'config/headers';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { FormState } from 'types/FormState';
import { FormComponent } from 'types/IFormSchemaJSON';
import { fetchDealers } from 'utils/fetchDealers';
import { fetchCarModels } from 'utils/fetchCarModels';
import styled, { css } from 'styled-components';
import { LocalFormState } from '@moller/design-system/utilities/forms/useLocalFormState';

interface SelectProps {
    component: FormComponent;
    form: LocalFormState<FormState>;
}

const SelectWrapper = styled.span`
    margin: 8px 0 22px;
`;

export const RequiredLabel = styled(Label)`
    ${({ theme }) => css`
        color: ${theme.PaletteNeutral50};
        position: absolute;
        margin-top: ${theme.SpacingBase}px;
        font-size: ${theme.TypefaceSizeXs}px;
    `}
`;

export const ErrorMessage = styled(Label)`
    ${({ theme }) => css`
        color: ${theme.ColorError};
        position: absolute;
        margin-top: ${theme.SpacingBase}px;
        display: flex;
        align-items: center;
        font-size: ${theme.TypefaceSizeXs}px;
    `}
`;

export const ErrorIcon = styled(Icon)`
    ${({ theme }) => css`
        color: ${theme.ColorError};
        height: ${theme.SizeXxs};
        width: ${theme.SizeXxs};
        padding-right: ${theme.SpacingBase}px;
        svg {
            height: calc(${theme.SizeXxs} * 0.666666);
        }
    `}
`;

const Select: React.FC<SelectProps> = ({ component, form }) => {
    const inputProps = form?.inputProps(component.key);
    const [options, setOptions] = useState<{ label: string; value: string }[]>(
        []
    );

    const [value] = useState<{ label: string; value: string }>(
        options.find((option) => option.value === inputProps.value)
    );

    /* 
        Assign options depending on:
            1) If they were set manually in formio under 'Data' tab in a Select component
            2) If 'options: dealerships' was set in formio under 'API' tab in a Select component
    */
    useEffect(() => {
        if (component.data?.values) {
            setOptions(component.data?.values);

            //setOptions([{ label: 'Hei', value: 'Skjer' }]);
        } else if (
            component.properties?.options &&
            component.properties.options === 'dealerships'
        ) {
            fetchDealers(headers, component.properties?.dealergroup, component.properties?.brand, component.properties?.dealertype)
                // @ts-ignore
                .then(setOptions)
                .catch(() => setOptions([]));
        } else if (
            component.properties?.options &&
            component.properties.options === 'carmodels'
        ) {
            fetchCarModels(headers, component.properties?.brand)
                // @ts-ignore
                .then(setOptions)
                .catch(() => setOptions([]));
        }
    }, [component, component.data?.values, component.properties?.options]);

    const handleOnChange = (option: { value: string }) => {
        option ? inputProps.setValue(option.value) : inputProps.setValue('');
    };
    const showNecessityTextForField = form.necessityText.includes(
        'med mindre noe annet er spesifisert'
    );
    const showFieldError =
        form.state.showError[component.key] && !form.state.validated.dealership;

    return (
        <SelectWrapper>
            <ReactSelect
                options={options}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        boxShadow: '1px solid black',
                        borderColor:
                            showFieldError && component.validate?.required
                                ? '#d82c0d'
                                : state.isFocused
                                ? 'black'
                                : '#747474',
                        '&:hover': {
                            borderColor: 'black',
                        },
                        padding: 2,
                        paddingLeft: 5,
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: 'black',
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 100,
                    }),
                }}
                isClearable
                isSearchable
                placeholder={component.label}
                onChange={handleOnChange}
                value={value}
            />
            {component?.validate?.required ? (
                showFieldError ? (
                    <ErrorMessage>
                        <ErrorIcon icon="error" />
                        Vennligst fyll ut dette feltet
                    </ErrorMessage>
                ) : showNecessityTextForField ? (
                    <RequiredLabel>Må fylles ut</RequiredLabel>
                ) : (
                    ''
                )
            ) : (
                ''
            )}
        </SelectWrapper>
    );
};

export default Select;
