import { useForm } from '@moller/design-system';
import { FormState } from 'types/FormState';
import { ComponentKeyLabel } from 'types/IFormSchemaJSON';
import { Error, InputLabel, InputWrapper, Required } from './Date';
import { ErrorIcon } from './Select';

const Time = ({
    componentKey,
    label,
    inputRef,
    required,
}: ComponentKeyLabel & any) => {
    const { form } = useForm<FormState>();
    const inputProps = form.inputProps(componentKey);
    const showFieldError = required && form.state.showError[componentKey];

    return (
        <InputWrapper hasValue={!!inputProps.value}>
            <InputLabel>{label}</InputLabel>
            <input
                type="time"
                id={componentKey}
                name={label}
                className="border border-gray rounded pr-2 pt-2 pb-2 pl-3 hover:border-black outline outline-0"
                onChange={(event) => {
                    inputProps.setValue(event.target.value);
                }}
                ref={inputRef}
                style={{
                    borderColor: showFieldError ? '#d82c0d' : '#747474',
                }}
            />
            {showFieldError ? (
                <Error>
                    <ErrorIcon icon="error" />
                    Vennligst fyll ut dette feltet
                </Error>
            ) : required ? (
                <Required>Må fylles ut</Required>
            ) : (
                ''
            )}
        </InputWrapper>
    );
};

export default Time;
