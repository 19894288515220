import AvailableValidators from 'config/availableValidators';
import { FormComponent } from 'types/IFormSchemaJSON';
import {
    noValidation,
    required,
    ValidatorConfig,
} from '@moller/design-system/utilities/validation';

const setValidatorFromFormSpec = (
    component: FormComponent
): ValidatorConfig<string> => {
    const isRequired = component.validate && component.validate.required;
    const hasValidator = component.properties?.validator;
    const hasNonValidValidator =
        hasValidator &&
        AvailableValidators[component.properties.validator] === undefined;

    if (isRequired) {
        if (hasValidator) {
            if (hasNonValidValidator) {
                return noValidation();
            }
            return required(
                AvailableValidators[component.properties.validator]
            );
        }
        return required();
    } else {
        if (hasValidator && !hasNonValidValidator) {
            return AvailableValidators[component.properties.validator];
        }
        return noValidation();
    }
};

export default setValidatorFromFormSpec;
