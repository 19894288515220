import { Label, useForm } from '@moller/design-system';
import { FormState } from 'types/FormState';
import { ComponentKeyLabel } from 'types/IFormSchemaJSON';
import styled, { css } from 'styled-components';
import { ErrorIcon, ErrorMessage, RequiredLabel } from './Select';

export const InputWrapper = styled.span<{ hasValue: boolean }>`
    ${(props) => css`
        ${({ theme }) => css`
            display: flex;
            flex-direction: column;
            margin: 8px 0 22px;
            position: relative;

            input {
                width: 100%;
                color: ${props.hasValue
                    ? theme.ColorDarkBackground
                    : theme.PaletteNeutral50};
            }

            &:focus-within {
                label:first-of-type {
                    color: ${theme.ColorDarkBackground};
                }
            }
        `}
    `}
`;

export const InputLabel = styled(Label)`
    ${({ theme }) => css`
        position: absolute;
        background-color: #fff;
        top: -14px;
        left: ${theme.SizeXxs};
        padding: 0 ${theme.SpacingBase}px;
        color: ${theme.PaletteNeutral50};
        font-size: ${theme.TypefaceSizeXs}px;
    `}
`;

export const Required = styled(RequiredLabel)`
    bottom: -22px;
`;

export const Error = styled(ErrorMessage)`
    bottom: -22px;
`;

const Date = ({
    componentKey,
    label,
    inputRef,
    required,
}: ComponentKeyLabel & any) => {
    const { form } = useForm<FormState>();
    const inputProps = form.inputProps(componentKey);
    const showFieldError = required && form.state.showError[componentKey];

    return (
        <InputWrapper hasValue={!!inputProps.value}>
            <InputLabel>{label}</InputLabel>
            <input
                type="date"
                id={componentKey}
                name={label}
                placeholder={label}
                className="border border-gray rounded pr-2 pt-2 pb-2 pl-3 hover:border-black outline outline-0"
                onChange={(event) => {
                    inputProps.setValue(event.target.value);
                }}
                ref={inputRef}
                style={{
                    borderColor: showFieldError ? '#d82c0d' : '#747474',
                }}
            />
            {showFieldError ? (
                <Error>
                    <ErrorIcon icon="error" />
                    Vennligst fyll ut dette feltet
                </Error>
            ) : required ? (
                <Required>Må fylles ut</Required>
            ) : (
                ''
            )}
        </InputWrapper>
    );
};

export default Date;
