import QR from 'components/QR';
import Valid from 'components/Valid';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Form } from './Form';

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route index element={<Form />}></Route>
                <Route path="/qr" element={<QR />}></Route>
                <Route path="/valid" element={<Valid />}></Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
