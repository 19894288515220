import { formInputs, SecondaryButton, Label } from '@moller/design-system';
import React from 'react';
import headers from '../config/headers';
import { FormState } from '../types/FormState';
import verifyPhoneNumber from '../utils/verifyPhoneNumber';
import styled, { css } from 'styled-components';

interface ISMSVerification {
    phoneNumber: string;
    setOTPFieldEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    setOTPValid: React.Dispatch<React.SetStateAction<boolean>>;
    setOTP: React.Dispatch<React.SetStateAction<string>>;
    OTPValid: boolean;
    OTPFieldEnabled: boolean;
}

const { TextField } = formInputs<FormState>();

const SMSWrapper = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        margin-top: -${theme.SpacingXs}px;
        column-gap: ${theme.SpacingXxs}px;

        button {
            margin: ${theme.SpacingXxs}px 0 0 0;
            display: flex;
        }

        > span {
            min-width: fit-content;
            display: flex;
            flex-direction: column;
        }
    `}
`;

const SMSVerification = ({
    phoneNumber,
    setOTPFieldEnabled,
    OTPValid,
    OTPFieldEnabled,
    setOTP,
    setOTPValid,
}: ISMSVerification) => {
    return (
        <>
            <Label>
                Vi sender deg en engangskode på SMS for å verifisere ditt
                telefonnummer.
            </Label>
            <SMSWrapper>
                <SecondaryButton
                    disabled={!phoneNumber}
                    key={'OTPButton'}
                    onClick={() => {
                        setOTPFieldEnabled(true);
                        verifyPhoneNumber(
                            JSON.stringify({
                                phoneNumber: phoneNumber,
                            }),
                            headers
                        );
                        setTimeout(
                            () => document.getElementById('OTP').focus(),
                            200
                        );
                    }}
                >
                    Send kode på SMS
                </SecondaryButton>
                <TextField
                    autoFocus
                    id="OTP"
                    label="Engangskode"
                    key={'OTP'}
                    disabled={!OTPFieldEnabled || !phoneNumber}
                    onChange={(event) => {
                        setOTPValid(true);
                        setOTP(event.target.value);
                    }}
                    field={''}
                    required={true}
                    validity={
                        OTPValid
                            ? {
                                  type: 'none',
                              }
                            : {
                                  type: 'error',
                                  message: 'Engangskoden er feil',
                              }
                    }
                />
            </SMSWrapper>
        </>
    );
};

export default SMSVerification;
