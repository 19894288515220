import { GET_CARMODELS_URL } from 'constants/backend';
import BackendResponse from 'types/BackendResponse';
import format from 'string-format';

export const fetchCarModels = (headers: Headers, brand: string) => {
    const brandParam = brand ? `?brand=${brand}` : '';

    return fetch(format(GET_CARMODELS_URL, brandParam), {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
    })
        .then((response) => response.json())
        .then((result: BackendResponse) => result.response)
        .catch((error) => console.log('error', error));
};
